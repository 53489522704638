import React from 'react';
import Fade from 'react-reveal/Fade';

import './DescriptionBlock.scss';

const DescriptionBlock = () => {
  return (
    <div className='description-block item'>
      <Fade bottom>
        <div className='description-block__text'>Мы используем ивент как инструмент создания живых коммуникаций между брендом и потребителем - </div>
        <h2 className='description-block__title description-block__title_empty'>ВНЕШНИМ</h2>
        <h2 className='description-block__title'>ИЛИ ВНУТРЕННИМ</h2>
      </Fade>
      <Fade bottom>
        <>
          <svg className='description-block__link hide-ball' viewBox='0 0 70 70' width='300px'>
            <defs>
              <path d='M35,35m-23,0a23,23 0 1,1 46,0a23,23 0 1,1 -46,0' fill='transparent' id='tophalf' />
            </defs>
            <path d='M35,35m-16,0a16,16 0 1,1 33,0a16,16 0 1,1 -33,0' fill='transparent' className='link-circle' />
            <g transform='translate(-90,-147)' id='layer1'>
              <g id='g952' transform='matrix(0.35277777,0,0,-0.35277777,11.755062,286.34107)'>
                <g id='g950' clipPath='url(#clipPath940)'>
                  <g id='g944' transform='translate(288,295.5)'>
                    <path
                      id='path942'
                      style={{
                        fill: 'none',
                        stroke: '#ffffff',
                        strokeWidth: '1',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: '10',
                        strokeDasharray: 'none',
                        strokePpacity: '1',
                      }}
                      d='M 0,0 H 64'
                    />
                  </g>
                  <g id='g948' transform='translate(338.5,281.5)'>
                    <path
                      id='path946'
                      style={{
                        fill: 'none',
                        stroke: '#ffffff',
                        strokeWidth: '1',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: '10',
                        strokeDasharray: 'none',
                        strokeOpacity: '1',
                      }}
                      d='M 0,0 C 0,8 7,14 14,14 7,14 0,20 0,28'
                    />
                  </g>
                </g>
              </g>
            </g>
            <text style={{ font: 'initial' }} className='link-circle__text'>
              <textPath xlinkHref='#tophalf' startOffset='5%' fill='#fff' className='description-block__link_text'>
                все проекты
              </textPath>
              <textPath xlinkHref='#tophalf' startOffset='42%' fill='#fff' className='description-block__link_text'>
                все проекты
              </textPath>
            </text>
          </svg>
        </>
      </Fade>
    </div>
  );
};

export default DescriptionBlock;
