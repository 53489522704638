/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade';

import * as CursorShapeAction from '../../redux/actions/CursorShapeAction';

import Logo from '../Logo';
import BurgerMenu from '../BurgerMenu';

import './Header.scss';

const Header = () => {
  const dispatch = useDispatch();

  const [coorY, setCoorY] = useState(0);
  const [isBlack, setBlack] = useState(false);

  useEffect(() => {
    const colorChangeCoorY = document.querySelector('.communications-block').getBoundingClientRect().top - 30;

    const onScroll = e => {
      setCoorY(e.target.documentElement.scrollTop);
    };

    document.addEventListener('scroll', onScroll);

    if (colorChangeCoorY <= 0) {
      dispatch(CursorShapeAction.getBlackCursor());
      setBlack(true);
    } else {
      dispatch(CursorShapeAction.getWhiteCursor());
      setBlack(false);
    }

    return () => document.removeEventListener('scroll', onScroll);
  }, [coorY, dispatch]);

  return (
    <header className='header'>
      <Fade>
        <div className='header__logo'>
          <Logo isBlack={isBlack} />
        </div>
        <div className='header__burger'>
          <BurgerMenu isBlack={isBlack} />
        </div>
      </Fade>
    </header>
  );
};

export default Header;
