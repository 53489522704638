/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import './FollowUs.scss';

const FollowUs = ({ isBlack = false }) => {
  return (
    <div className={`follow-us ${isBlack ? 'follow-us_black' : ''}`}>
      <div className='follow-us__icon' />
      <div className='follow-us__title'>Follow us</div>
      <ul className='follow-us__socials'>
        <li>
          <div className='parallax-wrap'>
            <a className='parallax-element' href='https://www.instagram.com/median.agency/' target='blank'>
              In
            </a>
          </div>
        </li>
        <li>
          <div className='parallax-wrap'>
            <a className='parallax-element' href='https://www.facebook.com/median.agency' target='blank'>
              Fb
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

FollowUs.defaultProps = {
  isBlack: false,
};

FollowUs.propTypes = {
  isBlack: PropTypes.bool,
};

export default FollowUs;
