/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import ArticleBlock from '../../components/ArticleBlock';

import ArrowLeft from '../../img/arrow-next.svg';

import './CommunicationsBlock.scss';

export const CommunicationsBlockRight = ({ countProject, communicationType, articleText, linkPath, linkText, imgSrc, imgLinkPath }) => {
  return (
    <div className='content-bg communications-block item'>
      <Fade bottom>
        <div className='communications-block__title'>
          <span className='count-project'>{countProject}</span>
          <h1 className='title title_empty'>{communicationType}</h1>
          <h1 className='title'>коммуникации</h1>
        </div>
      </Fade>
      <div className='communications-block__project-wrapper'>
        <Fade left>
          <div className='communications-block__article'>
            <ArticleBlock text={articleText} />
          </div>
        </Fade>
        <Fade right>
          <div className='communications-block__link-img'>
            <div className='communications-block__link-wrapper'>
              <Link to={linkPath} className='communications-block__link-img parallax-wrap'>
                <img src={ArrowLeft} alt='' className='parallax-element vertical' />
              </Link>
              <span className='communications-block__link'>{linkText}</span>
            </div>
            <div className='communications-block__project'>
              <Link to={imgLinkPath}>
                <img src={imgSrc} alt='' className='communications-block__img link-img' />
              </Link>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export const CommunicationsBlockLeft = ({ countProject, communicationType, articleText, linkPath, linkText, imgSrc, imgLinkPath }) => {
  return (
    <div className='content-bg communications-block item'>
      <Fade bottom>
        <div className='communications-block__title communications-block__title_left'>
          <span className='count-project'>{countProject}</span>
          <h1 className='title title_empty'>{communicationType}</h1>
          <h1 className='title'>коммуникации</h1>
        </div>
      </Fade>
      <div className='communications-block__project-wrapper communications-block__project-wrapper_left'>
        <Fade left>
          <div className='communications-block__link-img'>
            <div className='communications-block__project communications-block__project_left'>
              <Link to={imgLinkPath}>
                <img src={imgSrc} alt='' className='communications-block__img link-img' />
              </Link>
            </div>
            <div className='communications-block__link-wrapper communications-block__link-wrapper_right'>
              <Link to={linkPath} className='communications-block__link-img parallax-wrap'>
                <img src={ArrowLeft} alt='' className='parallax-element vertical' />
              </Link>
              <span className='communications-block__link'>{linkText}</span>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className='communications-block__article communications-block__article_right'>
            <ArticleBlock text={articleText} />
          </div>
        </Fade>
      </div>
    </div>
  );
};

CommunicationsBlockRight.propTypes = {
  countProject: PropTypes.string.isRequired,
  communicationType: PropTypes.string.isRequired,
  articleText: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgLinkPath: PropTypes.string.isRequired,
};

CommunicationsBlockLeft.propTypes = {
  countProject: PropTypes.string.isRequired,
  communicationType: PropTypes.string.isRequired,
  articleText: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgLinkPath: PropTypes.string.isRequired,
};
