import {
  DEFAULT_CURSOR,
  SMALL_CURSOR_STUCK,
  MEDIUM_CURSOR_STUCK,
  LARGE_CURSOR_STUCK,
  CURSOR_WITH_ARROW,
  DISSAPPEARING_CURSOR,
  APPEARANCE_CURSOR,
  SHOW_REEL_CURSOR,
  WHITE_CURSOR,
  BLACK_CURSOR,
} from '../constans';

export function getWhiteCursor() {
  return dispatch => {
    dispatch({ type: WHITE_CURSOR });
  };
}

export function getBlackCursor() {
  return dispatch => {
    dispatch({ type: BLACK_CURSOR });
  };
}

export function getDefaultCursor() {
  return dispatch => {
    dispatch({ type: DEFAULT_CURSOR });
  };
}

export function getSmallCursorStuck() {
  return dispatch => {
    dispatch({ type: SMALL_CURSOR_STUCK });
  };
}

export function getMediumCursorStuck() {
  return dispatch => {
    dispatch({ type: MEDIUM_CURSOR_STUCK });
  };
}

export function getLargeCursorStuck() {
  return dispatch => {
    dispatch({ type: LARGE_CURSOR_STUCK });
  };
}

export function getCursorWithArrow() {
  return dispatch => {
    dispatch({ type: CURSOR_WITH_ARROW });
  };
}

export function getDissappearingCursor() {
  return dispatch => {
    dispatch({ type: DISSAPPEARING_CURSOR });
  };
}

export function getAppearanceCursor() {
  return dispatch => {
    dispatch({ type: APPEARANCE_CURSOR });
  };
}

export function getShowReelCursor() {
  return dispatch => {
    dispatch({ type: SHOW_REEL_CURSOR });
  };
}
