import React from 'react';
import PropTypes from 'prop-types';

import './ArticleBlock.scss';

const ArticleBlock = ({ text }) => {
  return (
    <div className='article-block'>
      <p className='article-block__text'>{text}</p>
    </div>
  );
};

ArticleBlock.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ArticleBlock;
