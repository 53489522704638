import React from 'react';

import MainBlock from '../../containers/MainBlock';
import LiveCommunicationsBlock from '../../containers/LiveCommunicationsBlock';
import DescriptionBlock from '../../containers/DescriptionBlock';
import { CommunicationsBlockRight, CommunicationsBlockLeft } from '../../containers/CommunicationsBlock/CommunicationsBlock';
import OwnProjectBlock from '../../containers/OwnProjectBlock';
import StartProjectBlock from '../../containers/StartProjectBlock';

import GridGirls from '../../img/grid_girls.png';
import WorldGym from '../../img/WorldGym.png';
import OutOfTheList from '../../img/OutOfTheList.png';

import './MainPage.scss';

const MainPage = () => {
  return (
    <>
      <MainBlock />
      <LiveCommunicationsBlock />
      <DescriptionBlock />
      <CommunicationsBlockRight
        countProject='15'
        communicationType='внешние'
        articleText='Маркетинговое мероприятие / Промо-мероприятие / Фестиваль / Распродажа / Выставка / Конференция для клиентов / Презентация нового продукта / Инфотур ( fam-trip) / Пресс-конференция / Road-show / Форум'
        linkPath='/'
        linkText='Grid Girls F1'
        imgSrc={GridGirls}
        imgLinkPath='/'
      />
      <CommunicationsBlockLeft
        countProject='15'
        communicationType='внутренние'
        articleText='Конференция для сотрудников / Упаковка конференции / Тимбилдинг / Корпоративное событие / Презентация нового продукта / День рождения компании / Новогодний корпоратив / Стратегическая сессия'
        linkPath='/'
        linkText='World Gym 2018'
        imgSrc={WorldGym}
        imgLinkPath='/'
      />
      <CommunicationsBlockRight
        countProject='15'
        communicationType='OUT OF THE LIST'
        articleText='Опционально элемент коммуникации: Креатив, Копирайт, Дизайн, Менеджмент, Застройка - может быть проработан отдельно от события.'
        linkPath='/'
        linkText='Grid Girls F1'
        imgSrc={OutOfTheList}
        imgLinkPath='/'
      />
      <OwnProjectBlock />
      <StartProjectBlock />
    </>
  );
};

export default MainPage;
