import { SET_CURRENT_CURSOR_COORDINATES, UPDATE_CURSOR_COORDINATES, SET_CURSOR_STUCK } from '../constans';

export function setCurrentCursorCoordinates(Ox, Oy) {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_CURSOR_COORDINATES,
      payload: { Ox, Oy },
    });
  };
}

export function updateCursorCoordinates(Ox, Oy) {
  return dispatch => {
    dispatch({
      type: UPDATE_CURSOR_COORDINATES,
      payload: { Ox, Oy },
    });
  };
}

export function setCursorStuck(bool) {
  return dispatch => {
    dispatch({
      type: SET_CURSOR_STUCK,
      payload: bool,
    });
  };
}
