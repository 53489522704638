/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CarouselNextButton from './CarouselNextButton';

import Slider1 from '../../img/thumb-1920-548302.jpg';
import Slider2 from '../../img/thumb-1920-587508.jpg';
import Slider3 from '../../img/thumb-1920-600255.jpg';
import Slider4 from '../../img/thumb-1920-667676.jpg';
import Slider5 from '../../img/thumb-1920-982990.jpg';

import './Carousel.scss';

const Carousel = () => {
  const [activeSlide, setActiveSlide] = useState(1);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 0,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 150000,
    className: 'slick-wrapper',
    nextArrow: <CarouselNextButton activeSlide={activeSlide} />,
    appendDots: dots => (
      <div>
        <Fade>
          <span className='slick-dots__count'>01</span>
          <ul className='slick-dots__list'>{dots}</ul>
          <span className='slick-dots__count'>05</span>
        </Fade>
      </div>
    ),
    customPaging: i => <div className='slick-dots__paging'>{i + 1}</div>,
    afterChange: current => setActiveSlide(current + 1),
  };

  return (
    <>
      <Slider {...settings}>
        <div className='carousel'>
          <h2 className='slick-title'>
            <span>Grid Girls</span>
            <br />
            F1 GP
          </h2>
          <img src={Slider1} alt='Grid Girls F1 GP' className='slick-img see-case' />
        </div>
        <div className='carousel'>
          <h2 className='slick-title'>
            <span>MegaFon</span>
            <br />
            Bistro
            <br />
            4G
          </h2>
          <img src={Slider2} alt='MegaFon Bistro 4G' className='slick-img see-case' />
        </div>
        <div className='carousel'>
          <h2 className='slick-title'>
            <span>OZMall.</span>
            <br />
            Дай
            <br />
            Пять
          </h2>
          <img src={Slider3} alt='OZMall. Дай Пять' className='slick-img see-case' />
        </div>
        <div className='carousel'>
          <h2 className='slick-title'>
            <span>Kaspersky</span>
            <br />
            Lab
          </h2>
          <img src={Slider4} alt='Kaspersy Lab' className='slick-img see-case' />
        </div>
        <div className='carousel'>
          <h2 className='slick-title'>
            <span>WG</span>
            <br />
            Awards
          </h2>
          <img src={Slider5} alt='WG Awards' className='slick-img see-case' />
        </div>
      </Slider>
    </>
  );
};

export default Carousel;
