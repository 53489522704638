/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import LogoWhite from '../../img/logo.svg';
import LogoBlack from '../../img/logo-black.svg';

import './Logo.scss';

const Logo = ({ isBlack }) => {
  return (
    <div className={`logo ${isBlack ? 'logo_color_black' : 'logo_color_white'}`}>
      <img src={LogoWhite} alt='logo' className={`hide-ball logo__img ${isBlack ? 'logo__img_hidden' : 'logo__img_visible'}`} />
      <img src={LogoBlack} alt='logo' className={`hide-ball logo__img ${isBlack ? 'logo__img_visible' : 'logo__img_hidden'}`} />
    </div>
  );
};

Logo.propTypes = {
  isBlack: PropTypes.bool.isRequired,
};

export default Logo;
