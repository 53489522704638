/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import './BurgerMenu.scss';

const BurgerMenu = ({ isBlack }) => {
  return (
    <div id='burger-wrapper' className='burger-menu-wrapper parallax-wrap'>
      <div id='menu-burger' className='burger-menu parallax-element'>
        <span className={`burger-menu__item ${isBlack ? 'burger-menu__item_color_black' : 'burger-menu__item_color_white'}`} />
        <span className={`burger-menu__item ${isBlack ? 'burger-menu__item_color_black' : 'burger-menu__item_color_white'}`} />
      </div>
    </div>
  );
};

BurgerMenu.propTypes = {
  isBlack: PropTypes.bool.isRequired,
};

export default BurgerMenu;
