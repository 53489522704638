import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import Plus from '../../img/plus.svg';

import './StartProjectBlock.scss';

const StartProjectBlock = () => {
  return (
    <div className='content-bg start-project item'>
      <Fade left>
        <Link to='/' className='parallax-wrap start-project__plus'>
          <img src={Plus} alt='' className='parallax-element' />
        </Link>
      </Fade>
      <Fade bottom>
        <div to='/' className='start-project__header'>
          <h2 className='start-project__title start-project__title_empty'>Start a</h2>
          <h2 className='start-project__title'>project</h2>
        </div>
      </Fade>
    </div>
  );
};

export default StartProjectBlock;
