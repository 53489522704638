import React from 'react';
import { Route } from 'react-router-dom';

import Layout from './containers/Layout';

import MainPage from './pages/MainPage';

import './App.scss';

const App = () => {
  return (
    <Layout>
      <Route exact path='/' component={MainPage} />
    </Layout>
  );
};

export default App;
