/* eslint-disable func-names */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';

import * as CursorCoordinatesAction from '../../redux/actions/CursorCoordinatesAction';
import * as CursorShapeAction from '../../redux/actions/CursorShapeAction';

import './MagicCursor.scss';

const FOURTH = 0.25;

const MagicCursor = () => {
  const dispatch = useDispatch();

  const [pageYOffset, setPageYOffset] = useState(0);

  const ballRef = useRef(null);
  const ballSeeCaseRef = useRef(null);
  const showReelCaseRef = useRef(null);
  const magicCursorRef = useRef(null);

  const parallaxWrap = document.getElementsByClassName('parallax-wrap');
  const hideBall = document.getElementsByClassName('hide-ball');

  const { currentCursorOx, currentCursorOy } = useSelector(state => state.CursorCoordinatesReducer, shallowEqual);
  const { nextCursorOx, nextCursorOy } = useSelector(state => state.CursorCoordinatesReducer, shallowEqual);
  const { isCursorStuck } = useSelector(state => state.CursorCoordinatesReducer, shallowEqual);

  const cursorShape = useSelector(state => state.CursorShapeReducer, shallowEqual);

  useEffect(() => {
    setPageYOffset(window.pageYOffset || document.documentElement.scrollTop);
  }, [window.pageYOffset, document.documentElement.scrollTop]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    gsap.set(ballRef.current, { xPercent: -50, yPercent: -50 });
  }, []);

  useEffect(() => {
    const setCurrentCoordinates = e => dispatch(CursorCoordinatesAction.setCurrentCursorCoordinates(e.pageX, e.pageY - pageYOffset));

    document.addEventListener('mousemove', setCurrentCoordinates);

    return () => document.removeEventListener('mousemove', setCurrentCoordinates);
  }, [dispatch, pageYOffset]);

  /* Получает новое положение курсора и плавно перемещает его от текущего положения к новому */
  useEffect(() => {
    const updateCursorCoordinates = () => {
      if (isCursorStuck) return true;

      const newX = nextCursorOx + (currentCursorOx - nextCursorOx) * FOURTH;
      const newY = nextCursorOy + (currentCursorOy - nextCursorOy) * FOURTH;

      dispatch(CursorCoordinatesAction.updateCursorCoordinates(newX, newY));

      gsap.set(ballRef.current, { x: newX, y: newY });

      return null;
    };

    gsap.ticker.add(updateCursorCoordinates);

    return () => gsap.ticker.remove(updateCursorCoordinates);
  }, [currentCursorOx, currentCursorOy, dispatch, isCursorStuck, nextCursorOx, nextCursorOy]);

  useEffect(() => {
    dispatch(CursorShapeAction.getDefaultCursor());
  }, [dispatch, cursorShape.cursorColor]);

  /* Обновляет форму курсора */
  useEffect(() => {
    gsap.to(ballRef.current, 0.3, cursorShape);
  }, [cursorShape]);

  /* Когда курсор получает фокус parallax-wrap он прилепает */
  useEffect(() => {
    const mouseEnterEvent = e => {
      if (e.target.className.trim() === 'parallax-wrap start-project__plus') {
        dispatch(CursorShapeAction.getLargeCursorStuck());
        gsap.to(e.target.children, 0.3, { scale: 1 });
      } else if (
        e.target.className.trim() === 'communications-block__link-img parallax-wrap' ||
        e.target.className.trim() === 'parallax-wrap own-project__arrow-link' ||
        e.target.className.trim() === 'footer__link-info parallax-wrap'
      ) {
        dispatch(CursorShapeAction.getMediumCursorStuck());
        gsap.to(e.target.children, 0.3, { scale: 1 });
      } else if (e.target.children[0].localName === 'a') {
        dispatch(CursorShapeAction.getSmallCursorStuck());
        gsap.to(e.target.children, 0.3, { scale: 1 });
      } else {
        dispatch(CursorShapeAction.getSmallCursorStuck());
        gsap.to(e.target.children, 0.3, { scale: 0.75 });
      }

      dispatch(CursorCoordinatesAction.setCursorStuck(true));
    };

    [].forEach.call(parallaxWrap, e => e.addEventListener('mouseenter', mouseEnterEvent));

    return () => [].forEach.call(parallaxWrap, e => e.removeEventListener('mouseenter', mouseEnterEvent));
  }, [dispatch, parallaxWrap]);

  /* Когда курсор теряет фокус parallax-wrap он возвращается в дефолтное состояние */
  useEffect(() => {
    const mouseLeaveEvent = e => {
      if (e.toElement !== null && e.toElement.className.trim() === 'slick-next__img') {
        dispatch(CursorShapeAction.getCursorWithArrow());
      } else {
        dispatch(CursorShapeAction.getDefaultCursor());
      }

      gsap.to(e.target.children, 0.3, { scale: 1, x: 0, y: 0 });

      dispatch(CursorCoordinatesAction.setCursorStuck(false));
    };

    [].forEach.call(parallaxWrap, e => e.addEventListener('mouseleave', mouseLeaveEvent));

    return () => [].forEach.call(parallaxWrap, e => e.removeEventListener('mouseleave', mouseLeaveEvent));
  }, [dispatch, parallaxWrap]);

  /* Движение элемента к которому прилип курсор */
  useEffect(() => {
    const mouseMoveEvent = event => {
      const target = event.currentTarget;
      const parallaxElement = target.querySelector('.parallax-element');
      const rect = target.getBoundingClientRect();
      const posLeft = event.pageX - rect.left;
      const posTop = event.pageY - rect.top;

      const newX = rect.left + rect.width / 2 + (posLeft - rect.width / 2) / 2;
      const newY = rect.top + rect.height / 2 + (posTop - rect.height / 2 - pageYOffset) / 2;

      dispatch(CursorCoordinatesAction.updateCursorCoordinates(newX, newY));

      gsap.to(ballRef.current, 0.3, { x: newX, y: newY });

      if (
        event.target.className.trim() === 'communications-block__link-img parallax-wrap' ||
        event.target.className.trim() === 'parallax-element vertical'
      ) {
        gsap.to(parallaxElement, 0.3, {
          x: ((posTop - rect.height / 2 - pageYOffset) / rect.height) * 20,
          y: -((posLeft - rect.width / 2) / rect.width) * 20,
          ease: 'none',
        });
      } else {
        gsap.to(parallaxElement, 0.3, {
          x: ((posLeft - rect.width / 2) / rect.width) * 20,
          y: ((posTop - rect.height / 2 - pageYOffset) / rect.height) * 20,
          ease: 'none',
        });
      }
    };

    [].forEach.call(parallaxWrap, e => e.addEventListener('mousemove', mouseMoveEvent));

    return () => [].forEach.call(parallaxWrap, e => e.removeEventListener('mousemove', mouseMoveEvent));
  }, [dispatch, pageYOffset, parallaxWrap]);

  /* Наведение на картинки с ссылкой или next slider */
  useEffect(() => {
    const ballNext = document.getElementById('slick-next');
    const projectImg = document.getElementsByClassName('communications-block__project');
    const linkImg = document.getElementsByClassName('link-img');
    const mouseEnterEvent = () => {
      dispatch(CursorShapeAction.getCursorWithArrow());
    };

    ballNext.addEventListener('mouseenter', mouseEnterEvent);
    [].forEach.call(projectImg, e => e.addEventListener('mouseenter', mouseEnterEvent));
    [].forEach.call(linkImg, e => e.addEventListener('mouseenter', mouseEnterEvent));

    return () => {
      ballNext.removeEventListener('mouseenter', mouseEnterEvent);
      [].forEach.call(projectImg, e => e.removeEventListener('mouseenter', mouseEnterEvent));
      [].forEach.call(linkImg, e => e.removeEventListener('mouseenter', mouseEnterEvent));
    };
  }, [dispatch]);

  /* Уход курсора с картинок с ссылкой или next slider */
  useEffect(() => {
    const ballNext = document.getElementById('slick-next');
    const projectImg = document.getElementsByClassName('communications-block__project');
    const linkImg = document.getElementsByClassName('link-img');
    const mouseLeaveEvent = () => {
      gsap.set(ballRef.current, { css: { backgroundSize: '0' } });
      dispatch(CursorShapeAction.getDefaultCursor());
    };

    ballNext.addEventListener('mouseleave', mouseLeaveEvent);
    [].forEach.call(projectImg, e => e.addEventListener('mouseleave', mouseLeaveEvent));
    [].forEach.call(linkImg, e => e.addEventListener('mouseleave', mouseLeaveEvent));

    return () => {
      ballNext.removeEventListener('mouseleave', mouseLeaveEvent);
      [].forEach.call(projectImg, e => e.removeEventListener('mouseleave', mouseLeaveEvent));
      [].forEach.call(linkImg, e => e.removeEventListener('mouseleave', mouseLeaveEvent));
    };
  }, [dispatch]);

  /* Исчезновение курсора */
  useEffect(() => {
    const mouseLeaveEvent = () => dispatch(CursorShapeAction.getDissappearingCursor());

    [].forEach.call(hideBall, e => e.addEventListener('mouseenter', mouseLeaveEvent));

    return () => [].forEach.call(hideBall, e => e.removeEventListener('mouseenter', mouseLeaveEvent));
  }, [dispatch, hideBall]);

  /* Появление курсора */
  useEffect(() => {
    const mouseLeaveEvent = () => dispatch(CursorShapeAction.getAppearanceCursor());

    [].forEach.call(hideBall, e => e.addEventListener('mouseleave', mouseLeaveEvent));

    return () => [].forEach.call(hideBall, e => e.removeEventListener('mouseleave', mouseLeaveEvent));
  }, [dispatch, hideBall]);

  /* Курсор на главной банере слайдера */
  useEffect(() => {
    const slickImg = document.getElementsByClassName('slick-img');
    const mouseEnterEvent = () => {
      gsap.to(ballSeeCaseRef.current, 0, {
        delay: 0,
        left: '13%',
        top: '38%',
        opacity: 1,
      });

      dispatch(CursorShapeAction.getLargeCursorStuck());
    };

    [].forEach.call(slickImg, e => e.addEventListener('mouseenter', mouseEnterEvent));

    return () => [].forEach.call(slickImg, e => e.removeEventListener('mouseenter', mouseEnterEvent));
  }, [dispatch]);

  /* Курсор покаинул фокус слика */
  useEffect(() => {
    const slickImg = document.getElementsByClassName('slick-img');
    const mouseLeaveEvent = e => {
      gsap.to(ballSeeCaseRef.current, 0, {
        opacity: 0,
        left: '13%',
        top: '38%',
      });

      if (e.toElement !== null && e.toElement.id === 'burger-wrapper') {
        dispatch(CursorShapeAction.getSmallCursorStuck());
        gsap.to(e.target.children, 0.3, { scale: 0.75 });
        // eslint-disable-next-line
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { opacity: '0' });
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { clipPath: 'none', animation: 'none' });
        // eslint-disable-next-line
        gsap.to(ballRef.current, 0.3, { backgroundColor: 'transparent', ease: 'power2.out' });
        dispatch(CursorCoordinatesAction.setCursorStuck(true));
      } else if (e.toElement !== null && e.toElement.className.trim() === 'slick-next__img') {
        dispatch(CursorShapeAction.getCursorWithArrow());
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { opacity: '0' });
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { clipPath: 'none', animation: 'none' });
        gsap.to(ballRef.current, 0.3, { backgroundColor: 'transparent', ease: 'power2.out' });
      } else {
        dispatch(CursorShapeAction.getDefaultCursor());
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { opacity: '0' });
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { clipPath: 'none', animation: 'none' });
        gsap.to(ballRef.current, 0.3, { backgroundColor: 'transparent', ease: 'power2.out' });
      }
    };

    [].forEach.call(slickImg, e => e.addEventListener('mouseleave', mouseLeaveEvent));

    return () => [].forEach.call(slickImg, e => e.removeEventListener('mouseleave', mouseLeaveEvent));
  }, [dispatch]);

  /* Нажатие на баннер слайдера */
  useEffect(() => {
    const slickImg = document.getElementsByClassName('slick-img');
    const mouseClickEvent = e => {
      if (e.which === 1) {
        // eslint-disable-next-line
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { opacity: '1' });
        gsap.to(CSSRulePlugin.getRule('#ball:after'), {
          clipPath: 'polygon(50% 0%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 50%, 50% 50%)',
          animation: 'circle 1.5s linear forwards',
        });
        gsap.to(ballRef.current, 0.3, { backgroundColor: 'rgba(255, 255, 255, 0.3)', ease: 'power1.out' });
      }
    };

    [].forEach.call(slickImg, e => e.addEventListener('mousedown', mouseClickEvent));

    return () => [].forEach.call(slickImg, e => e.removeEventListener('mousedown', mouseClickEvent));
  }, []);

  /* Отжатие на баннере слайдера */
  useEffect(() => {
    const slickImg = document.getElementsByClassName('slick-img');
    const mouseUnclickEvent = e => {
      if (e.which === 1) {
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { opacity: '0' });
        gsap.to(CSSRulePlugin.getRule('#ball:after'), 0, { clipPath: 'none', animation: 'none' });
        gsap.to(ballRef.current, 0.3, { backgroundColor: 'transparent', ease: 'power2.out' });
      }
    };

    [].forEach.call(slickImg, e => e.addEventListener('mouseup', mouseUnclickEvent));

    return () => [].forEach.call(slickImg, e => e.removeEventListener('mouseup', mouseUnclickEvent));
  }, []);

  /* Курсор live communications */
  useEffect(() => {
    const liveCommunicationsTitle = document.getElementsByClassName('live-communications__title-wrapper');
    const mouseEnterEvent = () => {
      gsap.to(magicCursorRef.current, 0, { zIndex: '-1' });
      gsap.to(showReelCaseRef.current, 0, { delay: 0, left: '15%', top: '31%', opacity: 1 });
      dispatch(CursorShapeAction.getShowReelCursor());
    };

    [].forEach.call(liveCommunicationsTitle, e => e.addEventListener('mouseenter', mouseEnterEvent));

    return () => [].forEach.call(liveCommunicationsTitle, e => e.removeEventListener('mouseenter', mouseEnterEvent));
  }, [dispatch]);

  /* Курсор покинул live communications */
  useEffect(() => {
    const liveCommunicationsTitle = document.getElementsByClassName('live-communications__title-wrapper');
    const mouseLeaveEvent = e => {
      gsap.to(magicCursorRef.current, 0, { zIndex: '100000' });
      gsap.to(showReelCaseRef.current, 0, { delay: 0, left: '15%', top: '31%', opacity: 0 });

      if (e.toElement !== null && e.toElement.id === 'burger-wrapper') {
        dispatch(CursorShapeAction.getSmallCursorStuck());
        dispatch(CursorCoordinatesAction.setCursorStuck(true));
      } else {
        dispatch(CursorShapeAction.getDefaultCursor());
      }
    };

    [].forEach.call(liveCommunicationsTitle, e => e.addEventListener('mouseleave', mouseLeaveEvent));

    return () => [].forEach.call(liveCommunicationsTitle, e => e.removeEventListener('mouseleave', mouseLeaveEvent));
  }, [dispatch]);

  return (
    <>
      <div className='cd-cover-layer' />
      <div
        id='magic-cursor'
        className={`${cursorShape.cursorColor === 'white' ? 'magic-cursor--white' : 'magic-cursor--black'}`}
        ref={magicCursorRef}
      >
        <div id='ball' ref={ballRef}>
          <div id='ball-see-case' ref={ballSeeCaseRef}>
            Посмотреть кейс
          </div>
          <div id='show-reel' ref={showReelCaseRef}>
            Show reel
          </div>
        </div>
      </div>
    </>
  );
};

export default MagicCursor;
