import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import ArticleBlock from '../../components/ArticleBlock';

import './OwnProjectBlock.scss';

import ArrowLeft from '../../img/arrow-next.svg';
import BigHand from '../../img/big_hand.png';
import EventSurfing from '../../img/EventSurfing.png';
import ploSCHadka from '../../img/ploSCHadka.png';

const OwnProjectBlock = () => {
  return (
    <div className='content-bg own-project item'>
      <div>
        <Fade bottom>
          <div className='own-project__header'>
            <h1 className='title title_empty'>СОБСТВЕННЫЕ</h1>
            <h1 className='title'>проекты </h1>
          </div>
        </Fade>
        <Fade left>
          <div className='own-project__article'>
            <ArticleBlock
              text='Мы хотим реализовывать эффективные коммуникации, и для этого нам необходимо говорить на одном языке с нашими клиентами, партнёрами и подрядчиками. Мы убеждены, что равное профессиональное участие этих сторон в создании живых коммуникаций, их понимание каждого элемента события позволяет повысить показатели кампании в несколько раз. 

    Поэтому мы проводим серию образовательных вечеринок в Москве и Сочи и ежегодный форум креативной индустрии на юге России. '
            />
          </div>
        </Fade>
        <Fade bottom>
          <div className='own-project__horizontal-block own-project__horizontal-block-first'>
            <img src={BigHand} alt='' className='horizontal-block__img link-img' />
            <Link to='/' className='parallax-wrap own-project__arrow-link'>
              <img src={ArrowLeft} alt='' className='parallax-element' />
            </Link>
            <div className='own-project__link own-project__link_center'>
              <h4 className='link__title'>На свежем воздухе</h4>
              <p className='link__text'>
                Серия
                <br />
                образовательных
                <br />
                вечеринок в Сочи
              </p>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className='own-project__vertical-block own-project__vertical-block-second'>
            <img src={EventSurfing} alt='' className='horizontal-block__img link-img' />
            <Link to='/' className='parallax-wrap own-project__arrow-link'>
              <img src={ArrowLeft} alt='' className='parallax-element' />
            </Link>
            <div className='own-project__link'>
              <h4 className='link__title'>EventSurfing</h4>
              <p className='link__text'>
                Закрытый
                <br />
                образовательный
                <br />
                круиз
              </p>
            </div>
          </div>
        </Fade>
        <Fade left>
          <div className='own-project__horizontal-block own-project__horizontal-block-third'>
            <img src={ploSCHadka} alt='' className='horizontal-block__img link-img' />
            <Link to='/' className='parallax-wrap own-project__arrow-link'>
              <img src={ArrowLeft} alt='' className='parallax-element' />
            </Link>
            <div className='own-project__link own-project__link_center'>
              <h4 className='link__title'>ploSCHadka</h4>
              <p className='link__text'>
                Форум креативной
                <br />
                индустрии Юга
                <br />
                России
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default OwnProjectBlock;
