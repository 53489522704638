import React from 'react';
import Fade from 'react-reveal/Fade';

import './LiveCommunicationsBlock.scss';

const LiveCommunicationsBlock = () => {
  return (
    <div className='live-communications item'>
      <Fade bottom>
        <div className='live-communications__title-wrapper'>
          <div className='live-communications__title'>
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
            Communications
            <span className='live-communications__title_empty'>Live</span>
          </div>
        </div>
      </Fade>
      <div className='live-communications__section'>
        <Fade left>
          <div className='live-communications__wave-link cursor-hidden'>
            <svg
              xmlnsDc='http://purl.org/dc/elements/1.1/'
              xmlnsCc='http://creativecommons.org/ns#'
              xmlnsRdf='http://www.w3.org/1999/02/22-rdf-syntax-ns#'
              xmlnsSvg='http://www.w3.org/2000/svg'
              xmlns='http://www.w3.org/2000/svg'
              id='svg805'
              version='1.1'
              viewBox='0 0 260 240'
              height='45vw'
              width='45vw'
            >
              <defs id='defs799' />
              <g transform='translate(89.504761,31.612505)' id='layer1'>
                <path
                  d='m 136.16392,-17.929115 c 47.67334,19.5026134 -22.53932,32.665457 -12.73951,108.808662 1.83092,17.884423 10.22244,39.267343 18.64254,64.979903 9.77618,25.47126 -28.66778,64.99648 -76.460697,11.0176 -38.75581,-37.86575 -62.7651599,10.15259 -62.7651599,10.15259 -10.289469,19.10927 -24.2450041,21.96783 -36.7485061,8.32979 -9.008533,-9.17363 -6.437138,-27.03089 0.537633,-42.24761 3.572579,-8.77887 8.219369,-14.1993 6.696075,-18.12925 -2.613025,-6.85976 -16.896646,-6.69642 -16.896646,-6.69642 -21.158551,0 -53.989461,-9.08439 -40.633295,-42.734093 10.357203,-27.885322 47.567143,-63.102067 62.266687,-46.442842 54.551432,61.411202 66.488732,40.960672 69.903972,-40.178567 0,-37.048015 88.196907,-6.859763 88.196907,-6.859763 z'
                  style={{
                    fill: 'none',
                    stroke: '#ffffff',
                    // eslint-disable-next-line
                    strokeWidth: '0.35277775',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: '10',
                    strokeDasharray: 'none',
                    strokeOpacity: '1',
                  }}
                  id='path554'
                >
                  <animate
                    attributeName='d'
                    begin='0s'
                    dur='3s'
                    repeatCount='indefinite'
                    values='m 136.16392,-17.929115 c 47.67334,19.5026134 -22.53932,32.665457 -12.73951,108.808662 1.83092,17.884423 10.22244,39.267343 18.64254,64.979903 9.77618,25.47126 -28.66778,64.99648 -76.460697,11.0176 -38.75581,-37.86575 -62.7651599,10.15259 -62.7651599,10.15259 -10.289469,19.10927 -24.2450041,21.96783 -36.7485061,8.32979 -9.008533,-9.17363 -6.437138,-27.03089 0.537633,-42.24761 3.572579,-8.77887 8.219369,-14.1993 6.696075,-18.12925 -2.613025,-6.85976 -16.896646,-6.69642 -16.896646,-6.69642 -21.158551,0 -53.989461,-9.08439 -40.633295,-42.734093 10.357203,-27.885322 47.567143,-63.102067 62.266687,-46.442842 54.551432,61.411202 66.488732,40.960672 69.903972,-40.178567 0,-37.048015 88.196907,-6.859763 88.196907,-6.859763 z;
                            m 136.16392,-17.929115 c 47.67334,19.5026134 -22.53932,32.665457 -12.73951,108.808662 1.83092,17.884423 10.22244,39.267343 18.64254,64.979903 9.77618,25.47126 -28.66778,64.99648 -76.460697,11.0176 -38.75581,-37.86575 -62.7651599,10.15259 -62.7651599,10.15259 -10.289469,19.10927 -24.2450041,21.96783 -36.7485061,8.32979 -9.008533,-9.17363 -6.437138,-27.03089 0.537633,-42.24761 3.572579,-8.77887 8.219369,-14.1993 6.696075,-18.12925 -2.613025,-6.85976 -16.896646,-6.69642 -16.896646,-6.69642 -21.158551,0 -53.989461,-9.08439 -40.633295,-42.734093 10.357203,-27.885322 47.567143,-63.102067 62.266687,-46.442842 54.551432,61.411202 66.488732,40.960672 69.903972,-40.178567 0,-37.048015 88.196907,-6.859763 88.196907,-6.859763 z;
                            m 136.16392,-17.929115 c 47.67334,19.5026134 -22.53932,32.665457 -12.73951,108.808662 1.83092,17.884423 10.22244,39.267343 18.64254,64.979903 9.77618,25.47126 -28.66778,64.99648 -76.460697,11.0176 -38.75581,-37.86575 -62.7651599,10.15259 -62.7651599,10.15259 -10.289469,19.10927 -24.2450041,21.96783 -36.7485061,8.32979 -9.008533,-9.17363 -6.437138,-27.03089 0.537633,-42.24761 3.572579,-8.77887 8.219369,-14.1993 6.696075,-18.12925 -2.613025,-6.85976 -16.896646,-6.69642 -16.896646,-6.69642 -21.158551,0 -53.989461,-9.08439 -40.633295,-42.734093 10.357203,-27.885322 47.567143,-63.102067 62.266687,-46.442842 54.551432,61.411202 66.488732,40.960672 69.903972,-40.178567 0,-37.048015 88.196907,-6.859763 88.196907,-6.859763 z;
                            m 136.16392,-17.929115 c 47.67334,19.5026134 -22.53932,32.665457 -12.73951,108.808662 1.83092,17.884423 10.22244,39.267343 18.64254,64.979903 9.77618,25.47126 -28.66778,64.99648 -76.460697,11.0176 -38.75581,-37.86575 -62.7651599,10.15259 -62.7651599,10.15259 -10.289469,19.10927 -24.2450041,21.96783 -36.7485061,8.32979 -9.008533,-9.17363 -6.437138,-27.03089 0.537633,-42.24761 3.572579,-8.77887 8.219369,-14.1993 6.696075,-18.12925 -2.613025,-6.85976 -16.896646,-6.69642 -16.896646,-6.69642 -21.158551,0 -53.989461,-9.08439 -40.633295,-42.734093 10.357203,-27.885322 47.567143,-63.102067 62.266687,-46.442842 54.551432,61.411202 66.488732,40.960672 69.903972,-40.178567 0,-37.048015 88.196907,-6.859763 88.196907,-6.859763 z;
                            m 136.16392,-17.929115 c 47.67334,19.5026134 -22.53932,32.665457 -12.73951,108.808662 1.83092,17.884423 10.22244,39.267343 18.64254,64.979903 9.77618,25.47126 -28.66778,64.99648 -76.460697,11.0176 -38.75581,-37.86575 -62.7651599,10.15259 -62.7651599,10.15259 -10.289469,19.10927 -24.2450041,21.96783 -36.7485061,8.32979 -9.008533,-9.17363 -6.437138,-27.03089 0.537633,-42.24761 3.572579,-8.77887 8.219369,-14.1993 6.696075,-18.12925 -2.613025,-6.85976 -16.896646,-6.69642 -16.896646,-6.69642 -21.158551,0 -53.989461,-9.08439 -40.633295,-42.734093 10.357203,-27.885322 47.567143,-63.102067 62.266687,-46.442842 54.551432,61.411202 66.488732,40.960672 69.903972,-40.178567 0,-37.048015 88.196907,-6.859763 88.196907,-6.859763 z;
                            m 136.16392,-17.929115 c 47.67334,19.5026134 -22.53932,32.665457 -12.73951,108.808662 1.83092,17.884423 10.22244,39.267343 18.64254,64.979903 9.77618,25.47126 -28.66778,64.99648 -76.460697,11.0176 -38.75581,-37.86575 -62.7651599,10.15259 -62.7651599,10.15259 -10.289469,19.10927 -24.2450041,21.96783 -36.7485061,8.32979 -9.008533,-9.17363 -6.437138,-27.03089 0.537633,-42.24761 3.572579,-8.77887 8.219369,-14.1993 6.696075,-18.12925 -2.613025,-6.85976 -16.896646,-6.69642 -16.896646,-6.69642 -21.158551,0 -53.989461,-9.08439 -40.633295,-42.734093 10.357203,-27.885322 47.567143,-63.102067 62.266687,-46.442842 54.551432,61.411202 66.488732,40.960672 69.903972,-40.178567 0,-37.048015 88.196907,-6.859763 88.196907,-6.859763 z;
                            m 136.16392,-17.929115 c 47.67334,19.5026134 -22.53932,32.665457 -12.73951,108.808662 1.83092,17.884423 10.22244,39.267343 18.64254,64.979903 9.77618,25.47126 -28.66778,64.99648 -76.460697,11.0176 -38.75581,-37.86575 -62.7651599,10.15259 -62.7651599,10.15259 -10.289469,19.10927 -24.2450041,21.96783 -36.7485061,8.32979 -9.008533,-9.17363 -6.437138,-27.03089 0.537633,-42.24761 3.572579,-8.77887 8.219369,-14.1993 6.696075,-18.12925 -2.613025,-6.85976 -16.896646,-6.69642 -16.896646,-6.69642 -21.158551,0 -53.989461,-9.08439 -40.633295,-42.734093 10.357203,-27.885322 47.567143,-63.102067 62.266687,-46.442842 54.551432,61.411202 66.488732,40.960672 69.903972,-40.178567 0,-37.048015 88.196907,-6.859763 88.196907,-6.859763 z;'
                  />
                </path>
                <path
                  d='m 113.81256,-1.728627 c 46.76669,19.131844 -31.617357,15.777633 10.4528,144.248707 6.12458,21.52862 -15.10771,52.1529 -58.226317,9.03393 -32.406873,-34.6336 -60.8559317,7.83379 -60.8559317,7.83379 0,0 -11.673416,16.20132 -23.0127523,9.39764 -7.159625,-5.73757 -4.08587,-21.16807 1.633363,-33.64547 10.8895443,-23.26534 -11.759496,-25.47902 -11.759496,-25.47902 -14.537971,-1.90324 -44.270783,-5.40032 -33.319154,-32.992126 8.493478,-22.865998 31.795509,-39.924918 42.791944,-26.786062 41.921639,50.559048 78.507167,37.819187 80.847137,-40.831911 0,-30.378752 51.448407,-10.779478 51.448407,-10.779478 z'
                  style={{
                    fill: 'none',
                    stroke: '#ffffff',
                    strokeWidth: '0.35277775',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: '10',
                    strokeDasharray: 'none',
                    strokeOpacity: '1',
                  }}
                  id='path550'
                >
                  <animate
                    attributeName='d'
                    begin='0s'
                    dur='3s'
                    repeatCount='indefinite'
                    values='m 113.81256,-1.728627 c 46.76669,19.131844 -31.617357,15.777633 10.4528,144.248707 6.12458,21.52862 -15.10771,52.1529 -58.226317,9.03393 -32.406873,-34.6336 -60.8559317,7.83379 -60.8559317,7.83379 0,0 -11.673416,16.20132 -23.0127523,9.39764 -7.159625,-5.73757 -4.08587,-21.16807 1.633363,-33.64547 10.8895443,-23.26534 -11.759496,-25.47902 -11.759496,-25.47902 -14.537971,-1.90324 -44.270783,-5.40032 -33.319154,-32.992126 8.493478,-22.865998 31.795509,-39.924918 42.791944,-26.786062 41.921639,50.559048 78.507167,37.819187 80.847137,-40.831911 0,-30.378752 51.448407,-10.779478 51.448407,-10.779478 z;
                            m 113.81256,-1.728627 c 46.76669,19.131844 -31.617357,15.777633 10.4528,144.248707 6.12458,21.52862 -15.10771,52.1529 -58.226317,9.03393 -32.406873,-34.6336 -60.8559317,7.83379 -60.8559317,7.83379 0,0 -11.673416,16.20132 -23.0127523,9.39764 -7.159625,-5.73757 -4.08587,-21.16807 1.633363,-33.64547 10.8895443,-23.26534 -11.759496,-25.47902 -11.759496,-25.47902 -14.537971,-1.90324 -44.270783,-5.40032 -33.319154,-32.992126 8.493478,-22.865998 31.795509,-39.924918 42.791944,-26.786062 41.921639,50.559048 78.507167,37.819187 80.847137,-40.831911 0,-30.378752 51.448407,-10.779478 51.448407,-10.779478 z;
                            m 113.81256,-1.728627 c 46.76669,19.131844 -31.617357,15.777633 10.4528,144.248707 6.12458,21.52862 -15.10771,52.1529 -58.226317,9.03393 -32.406873,-34.6336 -60.8559317,7.83379 -60.8559317,7.83379 0,0 -11.673416,16.20132 -23.0127523,9.39764 -7.159625,-5.73757 -4.08587,-21.16807 1.633363,-33.64547 10.8895443,-23.26534 -11.759496,-25.47902 -11.759496,-25.47902 -14.537971,-1.90324 -44.270783,-5.40032 -33.319154,-32.992126 8.493478,-22.865998 31.795509,-39.924918 42.791944,-26.786062 41.921639,50.559048 78.507167,37.819187 80.847137,-40.831911 0,-30.378752 51.448407,-10.779478 51.448407,-10.779478 z;
                            m 113.81256,-1.728627 c 46.76669,19.131844 -31.617357,15.777633 10.4528,144.248707 6.12458,21.52862 -15.10771,52.1529 -58.226317,9.03393 -32.406873,-34.6336 -60.8559317,7.83379 -60.8559317,7.83379 0,0 -11.673416,16.20132 -23.0127523,9.39764 -7.159625,-5.73757 -4.08587,-21.16807 1.633363,-33.64547 10.8895443,-23.26534 -11.759496,-25.47902 -11.759496,-25.47902 -14.537971,-1.90324 -44.270783,-5.40032 -33.319154,-32.992126 8.493478,-22.865998 31.795509,-39.924918 42.791944,-26.786062 41.921639,50.559048 78.507167,37.819187 80.847137,-40.831911 0,-30.378752 51.448407,-10.779478 51.448407,-10.779478 z;
                            m 113.81256,-1.728627 c 46.76669,19.131844 -31.617357,15.777633 10.4528,144.248707 6.12458,21.52862 -15.10771,52.1529 -58.226317,9.03393 -32.406873,-34.6336 -60.8559317,7.83379 -60.8559317,7.83379 0,0 -11.673416,16.20132 -23.0127523,9.39764 -7.159625,-5.73757 -4.08587,-21.16807 1.633363,-33.64547 10.8895443,-23.26534 -11.759496,-25.47902 -11.759496,-25.47902 -14.537971,-1.90324 -44.270783,-5.40032 -33.319154,-32.992126 8.493478,-22.865998 31.795509,-39.924918 42.791944,-26.786062 41.921639,50.559048 78.507167,37.819187 80.847137,-40.831911 0,-30.378752 51.448407,-10.779478 51.448407,-10.779478 z;
                            m 113.81256,-1.728627 c 46.76669,19.131844 -31.617357,15.777633 10.4528,144.248707 6.12458,21.52862 -15.10771,52.1529 -58.226317,9.03393 -32.406873,-34.6336 -60.8559317,7.83379 -60.8559317,7.83379 0,0 -11.673416,16.20132 -23.0127523,9.39764 -7.159625,-5.73757 -4.08587,-21.16807 1.633363,-33.64547 10.8895443,-23.26534 -11.759496,-25.47902 -11.759496,-25.47902 -14.537971,-1.90324 -44.270783,-5.40032 -33.319154,-32.992126 8.493478,-22.865998 31.795509,-39.924918 42.791944,-26.786062 41.921639,50.559048 78.507167,37.819187 80.847137,-40.831911 0,-30.378752 51.448407,-10.779478 51.448407,-10.779478 z;
                            m 113.81256,-1.728627 c 46.76669,19.131844 -31.617357,15.777633 10.4528,144.248707 6.12458,21.52862 -15.10771,52.1529 -58.226317,9.03393 -32.406873,-34.6336 -60.8559317,7.83379 -60.8559317,7.83379 0,0 -11.673416,16.20132 -23.0127523,9.39764 -7.159625,-5.73757 -4.08587,-21.16807 1.633363,-33.64547 10.8895443,-23.26534 -11.759496,-25.47902 -11.759496,-25.47902 -14.537971,-1.90324 -44.270783,-5.40032 -33.319154,-32.992126 8.493478,-22.865998 31.795509,-39.924918 42.791944,-26.786062 41.921639,50.559048 78.507167,37.819187 80.847137,-40.831911 0,-30.378752 51.448407,-10.779478 51.448407,-10.779478 z;'
                  />
                </path>
                <path
                  d='m 93.028937,16.570375 c 30.761163,12.583937 -16.79681,25.890713 7.274993,108.086525 2.2606,8.97749 2.28388,14.28609 2.03623,16.01293 -2.527653,20.36692 -24.717383,11.38132 -44.072533,-7.97383 -21.315894,-22.78063 -44.693065,3.05576 -44.693065,3.05576 -4.4700483,4.12397 -10.8733163,13.5001 -16.2570583,9.87072 -4.708877,-3.77366 -1.017765,-13.37345 2.74425799,-21.58083 C 9.3726247,104.42862 -15.074874,102.51833 -15.074874,102.51833 c -3.355622,-0.39017 -30.123341,-2.09656 -22.919265,-20.245213 6.606469,-17.99096 27.35862,-0.403225 28.6494347,0.763765 C 35.233706,120.44544 80.423487,73.869247 78.895247,24.592188 78.513547,10.4585 93.028937,16.570375 93.028937,16.570375 Z'
                  style={{
                    fill: 'none',
                    stroke: '#ffffff',
                    strokeWidth: '0.35277775',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: '10',
                    strokeDasharray: 'none',
                    strokeOpacity: '1',
                  }}
                  id='path546'
                >
                  <animate
                    attributeName='d'
                    begin='0s'
                    dur='3s'
                    repeatCount='indefinite'
                    values='m 93.028937,16.570375 c 30.761163,12.583937 -16.79681,25.890713 7.274993,108.086525 2.2606,8.97749 2.28388,14.28609 2.03623,16.01293 -2.527653,20.36692 -24.717383,11.38132 -44.072533,-7.97383 -21.315894,-22.78063 -44.693065,3.05576 -44.693065,3.05576 -4.4700483,4.12397 -10.8733163,13.5001 -16.2570583,9.87072 -4.708877,-3.77366 -1.017765,-13.37345 2.74425799,-21.58083 C 9.3726247,104.42862 -15.074874,102.51833 -15.074874,102.51833 c -3.355622,-0.39017 -30.123341,-2.09656 -22.919265,-20.245213 6.606469,-17.99096 27.35862,-0.403225 28.6494347,0.763765 C 35.233706,120.44544 80.423487,73.869247 78.895247,24.592188 78.513547,10.4585 93.028937,16.570375 93.028937,16.570375 Z;
                            m 93.028937,16.570375 c 30.761163,12.583937 -16.79681,25.890713 7.274993,108.086525 2.2606,8.97749 2.28388,14.28609 2.03623,16.01293 -2.527653,20.36692 -24.717383,11.38132 -44.072533,-7.97383 -21.315894,-22.78063 -44.693065,3.05576 -44.693065,3.05576 -4.4700483,4.12397 -10.8733163,13.5001 -16.2570583,9.87072 -4.708877,-3.77366 -1.017765,-13.37345 2.74425799,-21.58083 C 9.3726247,104.42862 -15.074874,102.51833 -15.074874,102.51833 c -3.355622,-0.39017 -30.123341,-2.09656 -22.919265,-20.245213 6.606469,-17.99096 27.35862,-0.403225 28.6494347,0.763765 C 35.233706,120.44544 80.423487,73.869247 78.895247,24.592188 78.513547,10.4585 93.028937,16.570375 93.028937,16.570375 Z;
                            m 93.028937,16.570375 c 30.761163,12.583937 -16.79681,25.890713 7.274993,108.086525 2.2606,8.97749 2.28388,14.28609 2.03623,16.01293 -2.527653,20.36692 -24.717383,11.38132 -44.072533,-7.97383 -21.315894,-22.78063 -44.693065,3.05576 -44.693065,3.05576 -4.4700483,4.12397 -10.8733163,13.5001 -16.2570583,9.87072 -4.708877,-3.77366 -1.017765,-13.37345 2.74425799,-21.58083 C 9.3726247,104.42862 -15.074874,102.51833 -15.074874,102.51833 c -3.355622,-0.39017 -30.123341,-2.09656 -22.919265,-20.245213 6.606469,-17.99096 27.35862,-0.403225 28.6494347,0.763765 C 35.233706,120.44544 80.423487,73.869247 78.895247,24.592188 78.513547,10.4585 93.028937,16.570375 93.028937,16.570375 Z;
                            m 93.028937,16.570375 c 30.761163,12.583937 -16.79681,25.890713 7.274993,108.086525 2.2606,8.97749 2.28388,14.28609 2.03623,16.01293 -2.527653,20.36692 -24.717383,11.38132 -44.072533,-7.97383 -21.315894,-22.78063 -44.693065,3.05576 -44.693065,3.05576 -4.4700483,4.12397 -10.8733163,13.5001 -16.2570583,9.87072 -4.708877,-3.77366 -1.017765,-13.37345 2.74425799,-21.58083 C 9.3726247,104.42862 -15.074874,102.51833 -15.074874,102.51833 c -3.355622,-0.39017 -30.123341,-2.09656 -22.919265,-20.245213 6.606469,-17.99096 27.35862,-0.403225 28.6494347,0.763765 C 35.233706,120.44544 80.423487,73.869247 78.895247,24.592188 78.513547,10.4585 93.028937,16.570375 93.028937,16.570375 Z;
                            m 93.028937,16.570375 c 30.761163,12.583937 -16.79681,25.890713 7.274993,108.086525 2.2606,8.97749 2.28388,14.28609 2.03623,16.01293 -2.527653,20.36692 -24.717383,11.38132 -44.072533,-7.97383 -21.315894,-22.78063 -44.693065,3.05576 -44.693065,3.05576 -4.4700483,4.12397 -10.8733163,13.5001 -16.2570583,9.87072 -4.708877,-3.77366 -1.017765,-13.37345 2.74425799,-21.58083 C 9.3726247,104.42862 -15.074874,102.51833 -15.074874,102.51833 c -3.355622,-0.39017 -30.123341,-2.09656 -22.919265,-20.245213 6.606469,-17.99096 27.35862,-0.403225 28.6494347,0.763765 C 35.233706,120.44544 80.423487,73.869247 78.895247,24.592188 78.513547,10.4585 93.028937,16.570375 93.028937,16.570375 Z;
                            m 93.028937,16.570375 c 30.761163,12.583937 -16.79681,25.890713 7.274993,108.086525 2.2606,8.97749 2.28388,14.28609 2.03623,16.01293 -2.527653,20.36692 -24.717383,11.38132 -44.072533,-7.97383 -21.315894,-22.78063 -44.693065,3.05576 -44.693065,3.05576 -4.4700483,4.12397 -10.8733163,13.5001 -16.2570583,9.87072 -4.708877,-3.77366 -1.017765,-13.37345 2.74425799,-21.58083 C 9.3726247,104.42862 -15.074874,102.51833 -15.074874,102.51833 c -3.355622,-0.39017 -30.123341,-2.09656 -22.919265,-20.245213 6.606469,-17.99096 27.35862,-0.403225 28.6494347,0.763765 C 35.233706,120.44544 80.423487,73.869247 78.895247,24.592188 78.513547,10.4585 93.028937,16.570375 93.028937,16.570375 Z;
                            m 93.028937,16.570375 c 30.761163,12.583937 -16.79681,25.890713 7.274993,108.086525 2.2606,8.97749 2.28388,14.28609 2.03623,16.01293 -2.527653,20.36692 -24.717383,11.38132 -44.072533,-7.97383 -21.315894,-22.78063 -44.693065,3.05576 -44.693065,3.05576 -4.4700483,4.12397 -10.8733163,13.5001 -16.2570583,9.87072 -4.708877,-3.77366 -1.017765,-13.37345 2.74425799,-21.58083 C 9.3726247,104.42862 -15.074874,102.51833 -15.074874,102.51833 c -3.355622,-0.39017 -30.123341,-2.09656 -22.919265,-20.245213 6.606469,-17.99096 27.35862,-0.403225 28.6494347,0.763765 C 35.233706,120.44544 80.423487,73.869247 78.895247,24.592188 78.513547,10.4585 93.028937,16.570375 93.028937,16.570375 Z;'
                  />
                </path>
              </g>
            </svg>
          </div>
        </Fade>
        <Fade right>
          <article className='live-communications__article-wrapper'>
            <div className='live-communications__article'>
              Мы создаём коммуникации через события.
              <br />
              <br />
              На основе анализа уже имеющейся коммуникации бренда мы разрабатываем креативную концепцию мероприятия, подбираем стилизованное наполнение,
              внедряем в ивент эффективные digital-решения, реализуем проект и ставим чёткие KPI.
            </div>
          </article>
        </Fade>
      </div>
    </div>
  );
};

export default LiveCommunicationsBlock;
