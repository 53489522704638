export const SET_CURRENT_CURSOR_COORDINATES = 'SET_CURRENT_CURSOR_COORDINATES';
export const UPDATE_CURSOR_COORDINATES = 'UPDATE_CURSOR_COORDINATES';
export const SET_CURSOR_STUCK = 'SET_CURSOR_STUCK';

export const GET_REF_CURSOR = 'GET_REF_CURSOR';

/* Состояния курсора */
export const WHITE_CURSOR = 'WHITE_CURSOR';
export const BLACK_CURSOR = 'BLACK_CURSOR';

export const DEFAULT_CURSOR = 'DEFAULT_CURSOR';
export const SMALL_CURSOR_STUCK = 'SMALL_CURSOR_STUCK';
export const MEDIUM_CURSOR_STUCK = 'MEDIUM_CURSOR_STUCK';
export const LARGE_CURSOR_STUCK = 'LARGE_CURSOR_STUCK';
export const CURSOR_WITH_ARROW = 'CURSOR_WITH_ARROW';
export const DISSAPPEARING_CURSOR = 'DISSAPPEARING_CURSOR';
export const APPEARANCE_CURSOR = 'APPEARANCE_CURSOR';
export const SHOW_REEL_CURSOR = 'SHOW_REEL_CURSOR';
