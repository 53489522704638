/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Fade from 'react-reveal/Fade';

import Carousel from '../../components/Carousel';
import FollowUs from '../../components/FollowUs';

import './MainBlock.scss';

const MainBlock = () => {
  return (
    <div className='container-100vh item'>
      <Carousel />
      <Fade>
        <FollowUs />
      </Fade>
    </div>
  );
};

export default MainBlock;
