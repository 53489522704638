import React from 'react';
import { Link } from 'react-router-dom';

import FollowUs from '../FollowUs';

import ArrowLeft from '../../img/arrow-next.svg';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__link'>
        <Link to='/' className='footer__link-info parallax-wrap'>
          <img src={ArrowLeft} alt='' className='parallax-element' />
        </Link>
        <span className='footer__link-text'>info@median.agency</span>
      </div>
      <div className='footer__follow-us'>
        <FollowUs isBlack />
      </div>
      <div className='footer__copyright'>
        <div className='footer__copyright_wrapper'>
          <div className='footer__copyright_icon'>©</div>
          <div className='footer__copyright_title'>Copyright</div>
        </div>
        <div className='footer__copyright_socials'>
          <p>
            2019 ©
            <Link className='copyright_link hide-ball' href='/' target='blank'>
              median agency
            </Link>
            . Design by
            <Link className='copyright_link hide-ball' href='/' target='blank'>
              median
            </Link>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
