/* eslint-disable func-names */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import MagicCursor from '../../components/MagicCursor';

import './Layout.scss';

const Layout = props => {
  const { children } = props;

  return (
    <>
      <Header />
      <main className='main'>
        <div data-scroll>
          <div className='content'>{children}</div>
        </div>
      </main>
      <Footer />
      <MagicCursor />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
