/* eslint-disable no-undef */
import { SET_CURRENT_CURSOR_COORDINATES, UPDATE_CURSOR_COORDINATES, SET_CURSOR_STUCK } from '../constans';

export const initialState = {
  currentCursorOx: 0,
  currentCursorOy: 0,
  nextCursorOx: 0,
  nextCursorOy: 0,
  isCursorStuck: false,
};

export default function cursorCoordinatesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CURSOR_COORDINATES:
      return {
        ...state,
        currentCursorOx: action.payload.Ox,
        currentCursorOy: action.payload.Oy,
      };

    case UPDATE_CURSOR_COORDINATES:
      return {
        ...state,
        nextCursorOx: action.payload.Ox,
        nextCursorOy: action.payload.Oy,
      };

    case SET_CURSOR_STUCK:
      return {
        ...state,
        isCursorStuck: action.payload,
      };

    default:
      return state;
  }
}
