/* eslint-disable no-undef */
import {
  DEFAULT_CURSOR,
  SMALL_CURSOR_STUCK,
  MEDIUM_CURSOR_STUCK,
  LARGE_CURSOR_STUCK,
  CURSOR_WITH_ARROW,
  DISSAPPEARING_CURSOR,
  APPEARANCE_CURSOR,
  SHOW_REEL_CURSOR,
  WHITE_CURSOR,
  BLACK_CURSOR,
} from '../constans';

// initialState = default value cursor
export const initialState = {
  cursorColor: 'white',
  width: '15px',
  height: '15px',
  borderWidth: '2px',
  opacity: 1,
  backgroundColor: '#fff',
  borderColor: '#fff',
  backgroundSize: '0',
  backgroundPositionX: '0',
  scale: 1,
};

export default function cursorShapeReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_CURSOR:
      return {
        ...state,
        width: '15px',
        height: '15px',
        borderWidth: '2px',
        opacity: 1,
        backgroundColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        backgroundSize: '0',
        backgroundPositionX: '0',
        scale: 1,
      };

    case SMALL_CURSOR_STUCK:
      return {
        ...state,
        width: '52px',
        height: '52px',
        borderWidth: '1px',
        opacity: 1,
        backgroundColor: 'transparent',
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        backgroundSize: '0',
        backgroundPositionX: '0',
        scale: 1,
      };

    case MEDIUM_CURSOR_STUCK:
      return {
        ...state,
        width: '84px',
        height: '84px',
        borderWidth: '1px',
        opacity: 1,
        backgroundColor: 'transparent',
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        backgroundSize: '0',
        backgroundPositionX: '0',
        scale: 1,
      };

    case LARGE_CURSOR_STUCK:
      return {
        ...state,
        width: '130px',
        height: '130px',
        borderWidth: '1px',
        opacity: 1,
        backgroundColor: 'transparent',
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        backgroundSize: '0',
        backgroundPositionX: '0',
        scale: 1,
      };

    case CURSOR_WITH_ARROW:
      return {
        ...state,
        width: '130px',
        height: '130px',
        borderWidth: '1px',
        opacity: 1,
        backgroundSize: '69px 125px',
        backgroundColor: 'transparent',
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        backgroundPositionX: '27px',
        scale: 1,
      };

    case DISSAPPEARING_CURSOR:
      return {
        ...state,
        borderWidth: '1px',
        scale: 5,
        opacity: 0,
        backgroundColor: 'transparent',
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        backgroundSize: '0',
        backgroundPositionX: '0',
      };

    case APPEARANCE_CURSOR:
      return {
        ...state,
        borderWidth: '1px',
        scale: 1,
        opacity: 1,
        backgroundColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        backgroundSize: '0',
        backgroundPositionX: '0',
      };

    case SHOW_REEL_CURSOR:
      return {
        ...state,
        width: '75px',
        height: '75px',
        borderWidth: '1px',
        opacity: 1,
        backgroundSize: '0',
        backgroundColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
        backgroundPositionX: '0',
        scale: 1,
      };

    case WHITE_CURSOR:
      return {
        ...state,
        cursorColor: 'white',
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
      };

    case BLACK_CURSOR:
      return {
        ...state,
        cursorColor: 'black',
        borderColor: `${state.cursorColor === 'white' ? '#fff' : '#000'}`,
      };

    default:
      return state;
  }
}
