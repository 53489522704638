/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

import Slider1 from '../../../img/thumb-1920-548302.jpg';
import Slider2 from '../../../img/thumb-1920-587508.jpg';
import Slider3 from '../../../img/thumb-1920-600255.jpg';
import Slider4 from '../../../img/thumb-1920-667676.jpg';
import Slider5 from '../../../img/thumb-1920-982990.jpg';

import './CarouselNextButton.scss';

const CarouselNextButton = props => {
  const { activeSlide, onClick } = props;

  useEffect(() => {
    const nextButton = document.getElementById('slick-next');

    const mouseEnterEvent = () => {
      gsap.to(nextButton, 0, {
        top: 'calc(100vh - 354px)',
        width: 'calc(295px * 2.5)',
        height: 'calc(471px * 1.5)',
        backgroundColor: '#fff',
      });
    };

    nextButton.addEventListener('mouseenter', mouseEnterEvent);

    return () => {
      nextButton.removeEventListener('mouseenter', mouseEnterEvent);
    };
  }, []);

  useEffect(() => {
    const nextButton = document.getElementById('slick-next');

    const mouseLeaveEvent = () => {
      gsap.to(nextButton, 0, {
        top: 'calc(100vh - 236px)',
        width: 295,
        height: 471,
      });
    };

    nextButton.addEventListener('mouseleave', mouseLeaveEvent);

    return () => {
      nextButton.removeEventListener('mouseleave', mouseLeaveEvent);
    };
  }, []);

  /* useEffect(() => {
    const nextButton = document.getElementById('slick-next');
    const activeImg = document.querySelectorAll('.slick-next__img')[activeSlide - 1];

    const mouseClickEvent = () => {
      gsap
        .timeline()
        .to(document.querySelectorAll('.slick-next__img')[activeSlide - 1], 0, {
          opacity:
        })
        .to(activeImg, 2, {
          left: 0,
          opacity: 0,
        });

      gsap
        .timeline()
        .t
    };

    nextButton.addEventListener('click', mouseClickEvent);

    return () => {
      nextButton.removeEventListener('click', mouseClickEvent);
    };
  }, [activeSlide]); */

  return (
    <div id='slick-next' className='slick-next' onClick={onClick} role='button' tabIndex={0}>
      {[Slider2, Slider3, Slider4, Slider5, Slider1].map((slide, i) => {
        const isActive = activeSlide === 5 ? i === 4 : activeSlide === i + 1;

        return <img src={slide} alt={i} className={`${isActive ? 'slick-next__img_active' : ''} slick-next__img`} />;
      })}
    </div>
  );
};

CarouselNextButton.propTypes = {
  activeSlide: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CarouselNextButton;
